<template>
    <div class="article-detail-page wid1200">
        <bread-crumb :pN="pN" :childName="pageName"></bread-crumb>
        <div class="article-detail-wrap">
            <div class="left-article-detail">
                <div class="article-detail">
                    <h5>
                        <span v-if="detail && detail.searchTitle"><img v-if="detail.openAccessStatus == 1"
                                src="../assets/img/lock.png" alt="" /> <span v-html="detail.searchTitle"></span> </span>
                        <span v-else><img v-if="detail.openAccessStatus == 1" src="../assets/img/lock.png" alt="" />
                            <span v-html="detail.title"></span></span>
                    </h5>
                    <div class="top-article-detail">
                        <div class="info-article">
                            <div class="info">
                                <p>作者：<span v-if="detail && detail.author">{{detail.author}}</span></p>
                                <p>在线时间：<span v-if="detail && detail.releaseDate">{{detail.releaseDate}}</span></p>

                            </div>
                            <div class="info">
                                <p class="com-content">文章来源：<span
                                        v-if="detail && detail.bookTitle">{{detail.bookTitle}}</span></p>
                                <p>页码范围：<span v-if="detail && detail.pdfNums">{{detail.pdfNums}}</span></p>
                            </div>
                            <p>学科类别：<span v-if="detail && detail.subject">{{detail.subject}}</span></p>

                            <!-- <p class="com-content">关键词：<span
                                    v-if="detail && detail.keyWordCn">{{detail.keyWordCn}}</span></p> -->
                            <div class="btn-wrap">
                                <button v-if="((detail.outdated!=1) || (detail.startingType!=1)) && (detail.previewContent)"
                                    @click="readOnline(1, $event)">在线阅读</button>

                              <!-- detail.outdated==1过刊 -->
                                <!-- detail.startingType==1网络首发 -->
                                <button @click="readOnline(2, $event)">原版阅读</button>
                                <button @click="readOnline(3, $event)">下载阅读</button>
                                <button @click="getCollect">加入收藏</button>
                                <button @click="generateCitation">生成引文</button>
                            </div>
                            <!-- <div class="img-wrap">
                                <div class="img-wrap1 com-img">
                                    <img src="../assets/img/computeicon.png" @click="readOnline(1, $event)" alt="" />
                                    <div class="com-img-wrap" @click="readOnline(1, $event)">
                                        在线阅读
                                    </div>
                                </div>
                                <div class="img-wrap2 com-img">
                                    <img src="../assets/img/pdficon.png" alt="" @click="readOnline(2, $event)"/>
                                    <div class="com-img-wrap" @click="readOnline(2, $event)">
                                        原版阅读
                                    </div>
                                </div>
                                <div class="img-wrap3 com-img">
                                    <img src="../assets/img/downloadicon.png" alt="" @click="readOnline(3, $event)"/>
                                    <div class="com-img-wrap" @click="readOnline(3, $event)">
                                        PDF下载
                                    </div>
                                </div>
                                <div class="img-wrap4 com-img">
                                    <img src="../assets/img/staricon.png" alt="" @click="getCollect"/>
                                    <div class="com-img-wrap" @click="getCollect">
                                        收藏
                                    </div>
                                </div>
                                <div class="img-wrap5 com-img">
                                    <img src="../assets/img/shareicon.png" alt="" />
                                    <div class="code-img-wrap5 com-img-wrap">
                                        <div class="codeImg" id="codeImg" style="overflow: hidden;" ref="qrcodeContainers"></div>
                                    </div>
                                </div>

                            </div> -->
                        </div>
                        <div class="er-code">
                            <div class="er-code-wrap">
                                <!--用于存放canvas，隐藏-->
                                <div class="er codeImg"  id="codeImg"
                                    ref="qrcodeContainer"></div>
                                <!--用于存放二维码图片-->
                                <!-- <div style="width: 119px;height: 120px; display: none;" id="QRCodeId"></div> -->
                                <p>微信扫码阅读</p>
                                <!-- 手机阅读本书 -->
                            </div>

                        </div>
                    </div>
                    <!-- <div class="bottom-article-detail">
                        <ul class="nav-article">
                            <li :class="[activeIndex==1? 'active' : '']" @click="switchNav(1)">中文摘要</li>
                            <li :class="[activeIndex==2? 'active' : '']" @click="switchNav(2)">英文摘要</li>
                            <li :class="[activeIndex==3? 'active' : '']" @click="switchNav(3)">参考文献</li>
                        </ul>
                        <div class="article-content" v-if="detail && detail.abstractCn">
                            <p v-if="activeIndex==1" v-html="detail.abstractCn">

                            </p>
                            <p v-if="activeIndex==2" v-html="detail.abstractEn">

                            </p>
                            <p v-if="activeIndex==3" v-html="detail.reference">
                            </p>
                        </div>
                    </div> -->
                </div>

                <div class="article-msg">
                    <div class="com-wrap abstract-cn-wrap" v-if="detail.abstractCn">
                        <h6>摘要：</h6>
                        <!-- <p class="com-p"  v-html="detail.abstractCn"></p> -->
                        <div>
                            <p class="com-p abstract-cn-info" v-html="detail.abstractCn"></p>
                            <span id="abstract-cn-btn" class="abstract-cn-btn"
                                @click="showAllCn">{{isAllCn? '<<' : '>>' }}</span>
                        </div>

                    </div>
                    <div class="com-wrap keywords-wrap" v-if="detail.keyWordCn">
                        <h6>关键词：</h6>
                        <p class="com-p" v-for="(i, ind) in detail.keyWordCnList" :key="ind">{{i}}</p>
                    </div>
                    <div class="com-wrap abstract-en-wrap" v-if="detail.abstractEn">
                        <h6>Abstract：</h6>

                        <!-- <p class="com-p"  v-html="detail.abstractEn"></p> -->
                        <div>
                            <p class="com-p abstract-en-info" v-html="detail.abstractEn"></p>
                            <span id="abstract-en-btn" class="abstract-en-btn"
                                @click="showAllEn">{{isAllEn? '<<' : '>>' }}</span>
                        </div>
                    </div>
                    <div class="com-wrap keywords-en-wrap" v-if="detail.keyWordEn">
                        <h6>Keywords：</h6>
                        <p class="com-p" v-for="(i, ind) in detail.keyWordEnList" :key="ind">{{i}}</p>
                    </div>

                    <div class="com-wrap author-intro" v-if="detail.preAuthors">
                        <h6>作者简介： </h6>

                        <div class="author-intro-wrap">
                            <p class="com-p author-intro-info" v-html="detail.preAuthors"></p>
                            <span id="author-btn" class="author-btn"
                                @click="showAllAuthor">{{isAllAuthor? '<<' : '>>' }}</span>
                        </div>
                    </div>

                    <div class="article-body" v-if="(detail.outdated!=1) || (detail.startingType!=1)">
                        <!-- outdated==1过刊 -->
                        <!-- detail.startingType==1网络首发 -->
                        <h6>正文试读</h6>
                        <div class="article-content" v-html="detail.previewContent"></div>
                        <div class="btn">
                            <button @click="readOnline(3, $event)">下载阅读</button>
                        </div>
                    </div>

                </div>

                <div class="related-recommend">
                    <div class="com-title">
                        <div>
                            <img class="title-img" src="../assets/img/recomicon.png" alt="" />
                            <h5>相关推荐</h5>
                        </div>
                    </div>
                    <ul>
                        <li v-for="iterm in relevantList" :key="iterm.id" @click="goDetail(iterm.id, iterm.title)">
                            <p v-if="iterm">{{iterm.searchTitle ? iterm.searchTitle :iterm.title}}</p>
                            <span>{{iterm.author}}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="right-article-detail">
                <div class="article-source-wrap">
                    <div class="com-title">
                        <div>
                            <img class="title-img" src="../assets/img/articleicon.png" alt="" />
                            <h5>文章来源</h5>
                        </div>
                    </div>
                    <div class="article-source">
                        <img :src="jkDetail.imgPath" v-if="jkDetail.imgPath" @click="toJkDetail" alt="" />
                        <img :src="jkDetail.titleImg" v-else @click="toWJDetail" alt="" />

                        <div class="article-source-main">
                            <h5 @click="toJkDetail" v-if="jkDetail.name">{{jkDetail.name}}</h5>
                            <h5 @click="toWJDetail" v-else>{{jkDetail.title}}</h5>

                            <p v-if="jkDetail.author">主编：{{jkDetail.author}}</p>
                            <p v-else>主编：{{jkDetail.author}}</p>
                            <p v-if="jkDetail.description">集刊简介：<span v-html="jkDetail.description"></span></p>
                            <p v-else>简介：<span v-html="jkDetail.abstractCn"></span></p>

                        </div>
                    </div>
                </div>
                <div class="anthology-catalog-wrap">
                    <div class="com-title">
                        <div>
                            <img class="title-img" src="../assets/img/catalogicon.png" alt="" />
                            <h5>目录</h5>
                        </div>
                    </div>
                    <div class="anthology-catalog">
                        <ul>
                            <li v-for="(item, index) in catalogList" :key="index" @click="golink(item.id, item)"
                                v-html="item.searchTitle? item.searchTitle : item.title">
                            </li>
                        </ul>
                    </div>

                </div>
            </div>

        </div>

        <a id="downLaodAticle" style="display:none;" v-if="detail && detail.id"
            :href="'/admin/api/article/downLoad?id='+detail.id">下载阅读</a>
        <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose"
            :append-to-body="trueDate">
            <div class="tips" v-if="codeNum2">
                <span v-if="paylist.length>0" style="font-size: 16px;line-height: 30px;color: #333;">该资源需要付费
                    {{payPrice}} 元，请购买后再操作！</span>
                <span v-else style="font-size: 16px;line-height: 30px;color: #333;">该资源需要付费 {{payPrice}}
                    元，账号余额不足请先充值。</span>
            </div>
            <span class="tips  com-tips" v-else-if="tips == '您还未登录，请先登录！'"
                style="font-size: 16px;line-height: 30px;color:#333;">
                您还未登录，请先<span class="com-login-tips" @click="toLogin">登录</span>！
            </span>
            <span class="tips  com-tips" v-else v-html="tips"></span>

            <div slot="footer" class="dialog-footer">
                <el-button type="small" @click="showBuy" v-if="codeNum2">去购买</el-button>
                <el-button type="small" @click="toLogin" v-if="codeNum == '0'&&!hasPay">请登录</el-button>
            </div>
        </el-dialog>
        <el-dialog title="提示" :visible.sync="dialogBuy" width="30%" :before-close="handleBuyClose"
            :append-to-body="trueDate">
            <div class="tips tipsleft">
                <span
                    style="font-size: 16px;line-height: 30px; color: #333;margin-bottom: 4px;display: inline-block;">该资源需要付费
                    {{payPrice}} 元</span>
                <p style="font-size: 16px;line-height: 30px;">支付方式：</p>
                <p v-for="(item, index) in balanceInfo" :key="index"
                    style="font-size: 16px;line-height: 30px; padding-left: 20px;">
                    <el-radio v-model="radiobuy" :label="item.accounttype" style="font-size: 16px;line-height: 30px;">
                        {{item.accounttype=='common'?'现金账户余额':'充值卡余额'}}{{item.accountbalance}}</el-radio>
                </p>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="small" @click="handleBuyClose">取消</el-button>
                <el-button type="small" @click="gobuy">支付</el-button>
            </div>
        </el-dialog>
        <!-- <el-dialog title="提示" :visible.sync="dialogBuy" width="30%" :before-close="handleBuyClose"
            :append-to-body="trueDate">
            <div class="tips tipsleft">
                <span  style="font-size: 16px;line-height: 30px;">该资源需要付费 {{payPrice}}元</span>
                <p  style="font-size: 16px;line-height: 30px;">支付方式：</p>
                <p v-for="(item, index) in balanceInfo" :key="index"  style="font-size: 16px;line-height: 30px;">
                    <el-radio v-model="radiobuy" :label="item.accounttype"  style="font-size: 16px;line-height: 30px;">
                        {{item.accounttype=='common'?'现金账户余额':'充值卡余额'}}{{item.accountbalance}}</el-radio>
                </p>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="small" @click="handleBuyClose">取消</el-button>
                <el-button type="small" @click="gobuy">支付</el-button>
            </div>
        </el-dialog> -->
        <el-dialog title="提示" :visible.sync="dialogVisibledow" width="30%" :before-close="handleClosedow"
            :append-to-body="trueDate">
            <span class="tips">{{tipsdow}}</span>
            <div slot="footer" class="dialog-footer">
                <el-button type="small" @click="dialogVisibledow = !dialogVisibledow">确定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="提示" :visible.sync="isFree" width="30%">
            <!-- :before-close="handleClosedow"
            :append-to-body="trueDate" -->
            <span class="tips" style="font-size: 16px;line-height: 30px;color: #333;">此资源在线阅读、原版阅读可享免费，是否继续付费下载？</span>
            <div slot="footer" class="dialog-footer">
                <el-button type="small" @click="downloadNoFree">是</el-button>
                <el-button type="small" @click="isFree = !isFree">否</el-button>
            </div>
        </el-dialog>

        <el-dialog title="引文" :visible.sync="isQuote" width="30%">
            <div>
                <textarea id="copy" v-model="quoteContent"
                    style="width: 100%;   height: auto; min-height: 120px; border: none;font-size: 15px; line-height: 25px;color: #333;outline: none; resize:none"
                    readonly="readonly"></textarea>
                <div class="btn-wrap" style="text-align: center; margin-top: 30px;">
                    <button @click="copyQuote"
                        style="    width: 80px; height: 30px; background: #ff8600; font-size: 14px; font-weight: 500; color: #fff; margin-right: 20px; border: none; border-radius: 4px;">复制</button>
                </div>
            </div>
        </el-dialog>

        <el-dialog title="下载" :visible.sync="isDown" width="30%">
            <div>
                <span
                    style="width: 100%; height: auto; border: none;font-size: 15px; line-height: 25px;color: #333;">{{downMsg}}</span>
                <div class="btn-wrap" style="text-align: center; margin-top: 50px;">
                    <button @click="handleDown"
                        style="    width: 80px; height: 30px; background: #ff8600; font-size: 14px; font-weight: 500; color: #fff; margin-right: 20px; border: none; border-radius: 4px; margin-right: 20px;">下载</button>
                    <button @click="isDown = false"
                        style="    width: 80px; height: 30px; background: #ff8600; font-size: 14px; font-weight: 500; color: #fff; margin-right: 20px; border: none; border-radius: 4px;">取消</button>
                </div>
            </div>
        </el-dialog>

        <!-- <div class="wechat_box" v-show="shareWechat">
            <h5><span>分享到微信朋友圈</span><a @click="shareWechatHandle">×</a></h5>
            <div class="codeImg" id="codeImg" ref="qrcodeContainers"></div>
            <p>打开微信，点击底部的“发现”，</p>
            <p>使用“扫一扫”即可将网页分享至朋友圈。</p>
        </div> -->
        <div id="mjs_tip" class="tip" style="position: absolute; left: 0; top: 0; display: none;"></div>
    </div>
</template>

<script>
import BreadCrumb from '../components/BreadCrumb'
import QRCode from 'qrcodejs2'

export default {
    components: { BreadCrumb },
    data() {
        return {
            pN: '',
            pageName: '文章详情页',
            isQuote: false,
            quoteContent: '',
            isDown: false,
            downMsg: '',
            activeIndex: 1,
            detail: {},
            relevantList: [],
            jkDetail: '',
            catalogList: [],

            dialogVisible: false,
            tips: '',
            paylist: [],
            isAllAuthor: false,
            isAllCn: false,
            isAllEn: false,
            balanceInfo: [
                {
                    accounttype: 'common',
                    accountbalance: '3.23',
                },
                {
                    accounttype: 'commons',
                    accountbalance: '4.23',
                },
            ],
            payPrice: '',
            radiobuy: '',
            codeNum: '',
            trueDate: true,
            hasPay: false,
            dialogVisibledow: false,
            tipsdow: '',
            shareWechat: false,
            dialogBuy: false,
            isFree: false,
            codeNum2: false,
        }
    },
    watch: {
        $route: 'init',
    },
    mounted() {
        var _this = this
        setTimeout(() => {
            if (_this.detail.preAuthors) {
                _this.setCheckAll('.abstract-cn-info', 3, '.abstract-cn-btn')
                _this.setCheckAll('.abstract-en-info', 3, '.abstract-en-btn')
                _this.setCheckAll('.author-intro-info', 3, '.author-btn')
            }
        }, 500)
    },
    methods: {
        init() {
            if (this.$route.query.pN) {
                this.pN = decodeURI(this.$route.query.pN)
            } else {
                this.pN = ''
            }
            // this.pageName = this.$route.query.title
            this.getDetail(this.$route.query.id)
            this.getArticelList(this.$route.query.id)
        },
        showAllCn() {
            this.isAllCn = !this.isAllCn
            if (this.isAllCn) {
                $('.abstract-cn-info').css({ '-webkit-line-clamp': 'inherit', 'line-clamp': 'inherit' })
            } else {
                $('.abstract-cn-info').css({ '-webkit-line-clamp': '3', 'line-clamp': '3' })
            }
        },
        showAllEn() {
            this.isAllEn = !this.isAllEn
            if (this.isAllEn) {
                $('.abstract-en-info').css({ '-webkit-line-clamp': 'inherit', 'line-clamp': 'inherit' })
            } else {
                $('.abstract-en-info').css({ '-webkit-line-clamp': '3', 'line-clamp': '3' })
            }
        },
        showAllAuthor() {
            this.isAllAuthor = !this.isAllAuthor
            if (this.isAllAuthor) {
                $('.author-intro-info').css({ '-webkit-line-clamp': 'inherit', 'line-clamp': 'inherit' })
            } else {
                $('.author-intro-info').css({ '-webkit-line-clamp': '3', 'line-clamp': '3' })
            }
        },
        setCheckAll(select, num, more) {
            console.log(222)
            var height = $(select).height(),
                realityHeight = $(select).get(0).scrollHeight,
                lineHeight = $(select).css('line-height')
            lineHeight = lineHeight.split('px')[0]

            if (height + 1 >= lineHeight * num && realityHeight >= lineHeight * num) {
                $(more).css('display', 'block')

                // $(select).after('<p class="' + name+ '">' + '>>' + ' ></p>');
            } //lineHeight * num 是几行的高度，只要 实际的高度超过了设定的这个高度，就可以认为是超出了 num 行
        },
        copyQuote() {
            //获取input对象
            var input = document.getElementById('copy')
            //选中input标签
            input.select()
            //执行复制
            document.execCommand('copy')
            this.$message({
                message: '复制成功',
                type: 'success',
            })
        },
        generateCitation() {
            //生成引文
            this.quoteContent = this.detail.citation
            this.isQuote = true
            // console.log('this.quoteContent', this.quoteContent, 'this.isquote', this.isquote)
        },
        golink(id, title) {
            // this.$router.push({name:'iedolDetail',params:{id:id}})
            this.$router.push({ path: '/aD/a', query: { id: id, pN: encodeURI(this.pageName) } })
        },
        toJkDetail() {
            // this.$router.push({name:'iedol',params:{id:this.jkDetail.code}});
            // console.log('this.jkDetail', this.jkDetail)
            this.$router.push({ path: '/c/c', query: { id: this.jkDetail.id, pN: encodeURI(this.pageName) } })
            // , title: this.jkDetail.name
        },
        toWJDetail() {
            this.$router.push({ path: '/collectionDetail/c', query: { id: this.jkDetail.id, pN: encodeURI(this.pageName) } })
        },
        getWJDetail(id) {
            var _this = this
            _this.$http
                .get('/admin/api/book/detail', {
                    params: {
                        id: id,
                    },
                })
                .then((res) => {
                    _this.jkDetail = res.data.data
                    localStorage.setItem('jkDetail', JSON.stringify(_this.jkDetail))
                    // JSON.parse,
                    // _this.jkDetail.firstDate = _this.changeDate(this.jkDetail.firstDate)
                })
        },
        getJKDetail(id) {
            var _this = this
            _this.$http
                .get('/admin/api/cp/detail', {
                    params: {
                        idOrName: id,
                    },
                })
                .then((res) => {
                    _this.jkDetail = res.data.data
                    localStorage.setItem('jkDetail', JSON.stringify(_this.jkDetail))
                    // JSON.parse,
                    // _this.jkDetail.firstDate = _this.changeDate(this.jkDetail.firstDate)
                })
        },
        getQKList(id) {
            var _this = this
            _this.$http
                .get('/admin/api/book/catalog', {
                    params: {
                        id: id,
                        type: 'right',
                    },
                })
                .then((res) => {
                    _this.catalogList = res.data.data
                })
        },
        switchNav(num) {
            this.activeIndex = num
        },
        goDetail(id, title) {
            this.$router.push({ path: '/aD/a', query: { id: id, pN: encodeURI(this.pageName) } })
        },
        getDetail(id) {
            var _this = this
            _this.$http
                .get('/admin/api/article/detail', {
                    params: {
                        id: id,
                    },
                })
                .then((res) => {
                    var x = res.data.data
                    if (x.keyWordCn) {
                        x.keyWordCnList = x.keyWordCn.split(',')
                    } else {
                        x.keyWordCnList = []
                    }
                    if (x.keyWordEn) {
                        x.keyWordEnList = x.keyWordEn.split(',')
                    } else {
                        x.keyWordEnList = []
                    }
                    _this.detail = x

                    if (res.data.data && res.data.data.searchTitle) {
                        _this.pageName = res.data.data.searchTitle
                    } else {
                        _this.pageName = res.data.data.title
                    }

                    // if (detail.preAuthors) {
                    //     _this.setCheckAll()
                    // }
                    // console.log('111', _this.detail)
                    // console.log('222', _this.detail.collectedPapersId)

                    _this.$nextTick(function () {
                        // chart-main-wrap
                        // document.getElementsByClassName('note1')

                        let notes = document.querySelectorAll(".article-body font[class='note1']")
                        // console.log('notes', document.getElementsByClassName('note1'),notes)

                        for (var i = 0; i < notes.length; i++) {
                            notes[i].innerHTML = '<font style="color:red">' + (i + 1) + '</font>'
                        }
                        window.tip = {
                            Tips: function (ele) {
                                if (typeof ele == 'object') return ele
                                else if (typeof ele == 'string' || typeof ele == 'number') return document.getElementById(ele.toString())
                                return null
                            },
                            mousePos: function (e) {
                                var x, y
                                var e = e || window.event
                                return { x: e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft, y: e.clientY + document.body.scrollTop + document.documentElement.scrollTop }
                            },
                            start: function (obj) {
                                var self = this
                                var t = self.Tips('mjs_tip')
                                obj.onmousemove = function (e) {
                                    var mouse = self.mousePos(e)
                                    var toLeft = mouse.x + 10
                                    var toTop = mouse.y + 10
                                    t.style.left = toLeft + 'px'
                                    t.style.top = toTop + 'px'
                                    t.innerHTML = '注：'
                                    var str
                                    str = obj.getAttribute('tips')

                                    t.appendChild(document.createElement('div')).innerHTML = str
                                    t.style.display = ''
                                    var overTop = toTop + t.clientHeight - $(window).height() - $(window).scrollTop()
                                    var overLeft = toLeft + t.clientWidth - $(window).width() - $(window).scrollLeft()

                                    if (overTop > 0) {
                                        toTop = toTop - overTop - 2
                                        t.style.top = toTop + 'px'
                                    }
                                    if (overLeft > 0) {
                                        toLeft = toLeft - overLeft - 2
                                        t.style.left = toLeft + 'px'
                                    }
                                }
                                obj.onmouseout = function () {
                                    t.style.display = 'none'
                                }
                            },
                        }
                        // },500)
                    })

                    if (_this.detail.collectedPapersId) {
                        //集刊
                        _this.getJKDetail(_this.detail.collectedPapersId)
                        // _this.getHotList(_this.detail.collectedPapersId)
                    } else if (_this.detail.bookId) {
                        //文集
                        _this.getWJDetail(_this.detail.bookId)
                    }
                    _this.getQKList(_this.detail.bookId)
                    _this.$nextTick(() => {
                        // _this.showQRCode(_this.$refs.qrcodeContainers, _this.$route.query.id, 76, 76)
                        // _this.showQRCode(_this.$refs.qrcodeContainer, _this.$route.query.id, 134, 135)
                        _this.showQRCode(_this.$refs.qrcodeContainer, _this.$route.query.id, 119, 120)
                    })

                    // _this.detail.id
                    // _this.qkNum=res.data.data.collectnum;
                    // var page = res.data.data.pdfNums.split('-');
                    // _this.pageNum = page[1]-page[0]+1;
                    // if(res.data.data.keyWordCn){
                    //     this.detail.keywordList = res.data.data.keyWordCn.split(',');
                    // }else{
                    //     this.detail.keywordList = [];
                    // }
                })
        },
        showQRCode(ele, id, w, h) {
            var _this = this
            var codeUrl = ''
            // setTimeout(()=>{

            // },3000)
            this.$nextTick(() => {
                if (_this.$route.query.pN) {
                    var curUrl = window.location.href
                    var i = curUrl.indexOf('&pN')
                    codeUrl = curUrl.substring(0, i)
                } else {
                    codeUrl = window.location.href
                }
                var qrcode = new QRCode(ele, {
                    text: codeUrl,
                    width: w,
                    height: h,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    // correctLevel: 3
                    correctLevel: QRCode.CorrectLevel.H, //容错级别 QRCode.CorrectLevel.L， QRCode.CorrectLevel.M，QRCode.CorrectLevel.Q，QRCode.CorrectLevel.H
                })

                // var myCanvas = document.getElementsByTagName('canvas')[0]
                // var img = _this.convertCanvasToImage(myCanvas)
                // $('#QRCodeId').append(img)

                // console.log('code', qrcode)
                // console.log('codeUrl', codeUrl)
                qrcode.clear() // 清除代码
                qrcode.makeCode(codeUrl)
            })
        },
        //将canvas返回的图片添加到image里
        // convertCanvasToImage(canvas) {
        //     var image = new Image()
        //     image.src = canvas.toDataURL('image/png')
        //     return image
        // },
        getArticelList(id) {
            var _this = this
            _this.$http
                .get('/admin/api/article/recommend', {
                    params: {
                        id: id,
                    },
                })
                .then((res) => {
                    if (res.data.code == 0) {
                        _this.relevantList = res.data.data
                    }
                })
        },
        shareWechatHandle() {
            this.shareWechat = !this.shareWechat
            document.getElementById('bdshare_weixin_qrcode_dialog').remove()
        },
        handleBuyClose() {
            this.dialogBuy = false
        },
        handleClosedow() {
            this.dialogVisibledow = false
        },
        handleClose() {
            this.dialogVisible = false
        },
        showBuy() {
            this.dialogBuy = !this.dialogBuy
        },
        encodeURI2() {
            var url = encodeURIComponent(window.location.origin + encodeURIComponent(window.location.pathname) + window.location.search + window.location.hash)
            console.log(url)
            return url
        },
        toLogin() {
            window.location.href = '/admin/login.za?ZAS_referer=' + this.encodeURI2()
        },
        gobuy() {
            let _this = this
            _this.winUrl = '/admin/login.za?ZAS_referer=' + this.encodeURI2()
            _this
                .$http({
                    method: 'get',
                    url: '/admin/api/order/submit',
                    params: {
                        contentId: _this.$route.query.id,
                        payment: _this.radiobuy,
                        resourceType: 'literature',
                    },
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    },
                })
                .then((res) => {
                    if (res.data.code == 0) {
                        _this.codeNum = 0
                        _this.codeNum2 = false
                        _this.dialogBuy = false
                        _this.hasPay = true
                        _this.tips = '支付完成！'
                    } else {
                        _this.dialogVisibledow = true
                        _this.tipsdow = res.data.msg
                    }
                })
        },
        getCollect() {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/user/collection',
                params: {
                    id: _this.$route.query.id,
                    resourceType: 'ARTICLE', //this.$route.name=='aD' ? 'ARTICLE' : 'BOOK',
                    link: window.location.href,
                },
            }).then((res) => {
                _this.codeNum = res.data.code
                if (res.data.code == 0) {
                    _this.$notify({
                        title: '成功',
                        message: '收藏成功！',
                        type: 'success',
                    })
                }
                if (res.data.code == 402) {
                    _this.dialogVisible = true
                    _this.$notify({
                        title: '',
                        message: res.data.msg,
                        type: 'warning',
                    })
                }
                if (res.data.code == 401) {
                    //未登录
                    _this.dialogVisible = true
                    _this.tips = '您还未登录，请先登录！'
                }
                if (res.data.code == 500) {
                    //统一错误
                    _this.dialogVisible = true
                    _this.tips = res.data.msg
                }

                // console.log(_this.tips)
                if (res.data.code == 403) {
                    //无权限
                    _this.dialogVisible = true
                    _this.paylist = res.data.paylist
                    _this.payPrice = res.data.price
                }
            })
        },
        downloadNoFree() {
            this.isFree = false
            this.codeNum2 = true
            // document.getElementById('downLaodAticle').click()
        },
        handleDown() {
            document.getElementById('downLaodAticle').click()
            this.isDown = false
        },
        readOnline(type, e) {
            // this.curTab = 2
            // console.log(333333)
            e.preventDefault()
            var _this = this
            var result = _this.getCheck(type)
            result
                .then(function (res) {
                    if (res.data.code == 0) {
                        if (type == 1) {
                            // _this.$router.push({ name: 'readDetail', params: { id: _this.$route.params.id } })
                            _this.$router.push({ path: '/readOnline/r', query: { id: _this.$route.query.id, pN: encodeURI(_this.pageName) } })
                        }
                        if (_this.detail.existsAttachment) {
                            if (type == 2) {
                                sessionStorage.setItem('VIEWERTITLE', _this.detail.title)
                                window.open('/pdf/web/viewer.html?file=/admin/api/article/originalRead/' + _this.detail.id)
                            }
                            if (type == 3) {
                                if (res.data.msg && res.data.msg.slice(0, 9) == '您当前使用月卡下载') {
                                    _this.downMsg = res.data.msg
                                    _this.isDown = true
                                } else {
                                    document.getElementById('downLaodAticle').click()
                                }
                            }
                            if (type == 4) {
                                // document.getElementById('downLaodAticle').click()
                                // _this.getCollect()
                            }
                        } else {
                            _this.dialogVisibledow = true
                            _this.tipsdow = '数据迁移中，请耐心等待噢'
                        }
                    }
                })
                .catch(function (res) {
                    _this.dialogVisible = true
                    if (res.code == 401) {
                        //未登录
                        _this.tips = '您还未登录，请先登录！'
                    }
                    if (res.code == 1002) {
                        //无权限
                        // console.log('未支付')
                        _this.paylist = res.paylist
                        _this.balanceInfo = res.balanceInfo
                        _this.payPrice = res.price
                        _this.tips = '这个资源需要付费，请购买后查看！'

                        // if (type == 3) {
                        //     _this.codeNum2 = false
                        //     _this.isFree = true
                        // } else {
                        _this.isFree = false
                        _this.codeNum2 = true
                        // }
                    }
                    if (res.code == 500) {
                        //统一错误
                        _this.tips = res.msg
                    }
                })
        },
        getCheck(type) {
            let _this = this
            var promise = new Promise(function (resolve, reject) {
                _this.$http
                    .get('/admin/api/order/check', {
                        params: {
                            id: _this.$route.query.id,
                            type: type,
                            resourceType: 'literature',
                        },
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                        },
                    })
                    .then((res) => {
                        _this.codeNum = res.data.code
                        if (res.data.code == 0) {
                            // _this.isLogin = res.data.user.isLogined;
                            // if(_this.isLogin){
                            //     if(res.data.user.BranchInfoName){
                            //         _this.userRole = 1;//机构用户
                            //     }else{
                            //         _this.userRole = 2;//个人用户
                            //     }
                            // }
                            resolve(res)
                        } else {
                            reject(res.data)
                        }
                    })
            })
            return promise
        },
    },
    created() {
        this.init()
    },
}
</script>

<style lang="scss" scoped>
.article-detail-page {
    .article-detail-wrap {
        display: flex;
        justify-content: space-between;
    }
    .com-title {
        height: 45px;
        line-height: 45px;
        border-bottom: 2px solid #dedede;
        display: flex;
        align-items: center;
        justify-content: space-between;
        div {
            display: flex;
            align-items: center;

            img {
                width: 24px;
                height: 24px;
                margin-right: 11px;
                margin-left: 3px;
            }
            h5 {
                height: 45px;
                line-height: 45px;
                font-size: 24px;
                color: #000000;
                border-bottom: 3px solid #bd1a2d;
            }
        }

        ul {
            display: flex;
            li {
                font-size: 18px;
                margin-right: 18px;
                cursor: pointer;
                line-height: 36px;
                color: #333;
            }
        }
        span {
            font-size: 14px;
            color: #404040;
            cursor: pointer;
            img {
                width: 4px;
                height: 8px;
                margin-left: 10px;
            }
        }
    }
    .left-article-detail {
        width: 830px;
        .article-detail {
            padding: 0 28px 21px;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            h5 {
                padding-top: 10px;

                span {
                    // display: block;
                    // width: 144px;
                    height: 41px;
                    line-height: 46px;
                    font-size: 20px;
                    color: #000;
                    margin-bottom: 10px;
                    padding-top: 8px;
                    border-bottom: 3px solid #bd1a2d;
                    img {
                        width: 26px;
                        margin-top: 11px;
                        vertical-align: top;
                    }
                    span >>> .role4 {
                        vertical-align: middle;
                        width: 20px !important;
                        height: 20px !important;
                        margin-top: -2px;
                    }
                }

            }
            .top-article-detail {
                display: flex;
                justify-content: center;
                .info-article {
                    width: 632px;
                    min-height: 150px;
                    padding-top: 10px;
                    position: relative;

                    .info {
                        display: flex;
                        justify-content: center;
                    }
                    p {
                        font-size: 14px;
                        // color: #666;
                        line-height: 30px;
                        color: #000;
                        span {
                            font-size: 14px;
                            // color: #333;
                            margin-right: 18px;
                            color: #000;
                        }
                        // img {
                        //     width: 24px;
                        //     height: 24px;
                        //     margin-right: 24px;
                        //     cursor: pointer;
                        //     vertical-align: middle;
                        // }
                        // img:first-child {
                        //     margin-left: 9px;
                        // }
                    }
                    p:first-child {
                        width: 320px;
                    }
                    p:nth-child(2) {
                        flex: 1;
                    }

                    // .img-wrap {
                    //     display: flex;

                    //     .com-img {
                    //         width: auto;
                    //         position: relative;
                    //         margin-right: 24px;
                    //         img {
                    //             width: 24px;
                    //             height: 24px;
                    //             margin-top: 8px;
                    //             margin-bottom: 20px;
                    //             cursor: pointer;
                    //             vertical-align: middle;
                    //         }

                    //         .com-img-wrap {
                    //             position: absolute;
                    //             left: 50%;
                    //             top: 42px;
                    //             transform: translateX(-50%);
                    //             width: 80px;
                    //             box-sizing: border-box;
                    //             text-align: center;
                    //             line-height: 30px;
                    //             font-size: 14px;
                    //             border-radius: 4px;
                    //             display: none;
                    //             border: 1px solid #eee;
                    //             cursor: pointer;
                    //             color: #333;
                    //         }
                    //         .code-img-wrap5 {
                    //             width: 90px;
                    //             height: 90px;
                    //             text-align: center;
                    //             line-height: 90px;
                    //             background: #fff;
                    //             padding-left: 6px;
                    //             padding-top: 6px;
                    //             div {
                    //                 width: 76px;
                    //                 height: 76px;
                    //             }
                    //         }
                    //         .com-img-wrap::before {
                    //             box-sizing: content-box;
                    //             width: 0px;
                    //             height: 0px;
                    //             position: absolute;
                    //             top: -16px;
                    //             // right: 41px;
                    //             left: 50%;
                    //             transform: translateX(-50%);
                    //             padding: 0;
                    //             border-bottom: 8px solid #ffffff;
                    //             border-top: 8px solid transparent;
                    //             border-left: 8px solid transparent;
                    //             border-right: 8px solid transparent;
                    //             display: block;
                    //             content: '';
                    //             z-index: 12;
                    //         }
                    //         .com-img-wrap::after {
                    //             box-sizing: content-box;
                    //             width: 0px;
                    //             height: 0px;
                    //             position: absolute;
                    //             top: -18px;
                    //             // right: 40px;
                    //             left: 50%;
                    //             transform: translateX(-50%);
                    //             padding: 0;
                    //             border-bottom: 9px solid #cccccc;
                    //             border-top: 9px solid transparent;
                    //             border-left: 9px solid transparent;
                    //             border-right: 9px solid transparent;
                    //             display: block;
                    //             content: '';
                    //             z-index: 10;
                    //         }
                    //         // div::before {
                    //         //     content: '';
                    //         //     display: block;
                    //         //     width: 0;
                    //         //     height: 0;
                    //         //     border-left: 5px solid transparent;
                    //         //     border-right: 5px solid transparent;
                    //         //     border-bottom: 10px solid red;
                    //         //     position: absolute;
                    //         //     top: -9px;
                    //         //     left: 50%;
                    //         //     transform: translateX(-50%);

                    //         // }
                    //     }
                    //     .com-img:hover .com-img-wrap {
                    //         display: block;
                    //     }
                    //     .com-img:first-child {
                    //         margin-left: 9px;
                    //         // div {
                    //         //     background: red;
                    //         // }
                    //     }
                    // }
                    .btn-wrap {
                        // position: absolute;
                        // bottom: 0;
                        margin-top: 20px;
                        button {
                            width: 90px;
                            height: 30px;
                            background: #ff8600;
                            font-size: 14px;
                            color: #fff;
                            margin-right: 20px;
                            border: none;
                            border-radius: 4px;
                        }
                    }
                }
                .er-code {
                    flex: 1;

                    div.er-code-wrap {
                        box-sizing: border-box;
                        width: 140px; //160px;
                        height: 140px; //160px;
                        padding: 10px 10px;

                        box-sizing: border-box;
                        background: url(../assets/img/recticon.png);
                        background-size: 100% 100%;
                        background-position: 0 0;
                        .er {
                            width: 119px;
                            height: 120px;
                            margin-bottom: 6px;
                            overflow: hidden;
                            // margin-left: 10px;
                        }
                        p {
                            text-align: center;
                            color: #bd1a2d;
                            font-size: 12px;
                            line-height: 12px;
                        }
                        img {
                            display: block;
                            width: 134px;
                            height: 135px;
                        }
                    }
                }
            }
            .bottom-article-detail {
                ul.nav-article {
                    display: flex;
                    height: 42px;
                    border-bottom: 2px solid #bd1a2d;
                    margin-top: 23px;
                    margin-bottom: 18px;
                    li {
                        width: 140px;
                        height: 42px;
                        text-align: center;
                        line-height: 42px;
                        font-size: 18px;
                        color: #333;
                        background: #fff;
                        cursor: pointer;
                    }
                    li.active {
                        color: #fff;
                        background: #bd1a2d;
                    }
                }
                .article-content {
                    p {
                        line-height: 26px;
                        height: 184px;
                        font-size: 16px;
                        color: #666;
                        text-overflow: -o-ellipsis-lastline;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 7;
                        line-clamp: 7;
                        -webkit-box-orient: vertical;
                    }
                }
            }
        }
        .article-msg {
            margin-top: 22px;
            padding: 20px 28px 24px;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            .com-wrap {
                position: relative;
                margin-bottom: 15px;
                h6 {
                    font-size: 16px;
                    color: #bd1a2d;
                    position: absolute;
                    left: 0;
                    line-height: 24px;
                }
                .com-p {
                    font-size: 14px;
                    color: #333;
                    line-height: 24px;
                    text-indent: 20px;
                    text-indent: 46px;
                    // padding-top: 2px;
                }
            }
            .abstract-cn-wrap {
                position: relative;
                h6 {
                }
            }
            .keywords-wrap {
                // margin-bottom: 15px;
                display: flex;
                flex-wrap: wrap;
                h6 {
                    position: inherit;
                }
                .com-p {
                    text-indent: 0px;
                    margin-right: 10px;
                }
            }
            .abstract-en-wrap {
                position: relative;
                .com-p {
                    text-indent: 77px;
                }
            }

            .abstract-cn-info,
            .abstract-en-info {
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                line-clamp: 3;
                -webkit-box-orient: vertical;
                line-height: 24px;
            }
            .abstract-cn-btn,
            .abstract-en-btn {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 24px;
                line-height: 24px;
                text-align: center;
                cursor: pointer;
                background: none repeat scroll 0 0 #ffffff;
                color: #727171;
                display: none;
            }

            .keywords-en-wrap {
                // margin-bottom: 15px;
                display: flex;
                flex-wrap: wrap;
                h6 {
                    position: inherit;
                }
                .com-p {
                    text-indent: 0px;
                    margin-right: 15px;
                }
            }
            .author-intro {
                display: block;
                // h6 {
                //     position: inherit;
                // }
                position: relative;
                .com-p {
                    text-indent: 76px;
                }
                .author-intro-info {
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    line-clamp: 3;
                    -webkit-box-orient: vertical;
                    line-height: 24px;
                }
                .author-btn {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 24px;
                    line-height: 24px;
                    text-align: center;
                    cursor: pointer;
                    background: none repeat scroll 0 0 #ffffff;
                    color: #727171;
                    display: none;
                }
            }
            .article-body {
                margin-top: 25px;
                background: #f8f8f8;
                padding: 0 10px 11px;
                h6 {
                    font-size: 16px;
                    font-weight: Bold;
                    text-align: center;
                    color: #bd1a2d;
                    line-height: 32px;
                }
                .article-content {
                    font-size: 14px;
                    font-family: Microsoft YaHei, Microsoft YaHei-Normal;
                    font-weight: Normal;
                    text-align: left;
                    color: #333333;
                    line-height: 28px;
                }
                .btn {
                    text-align: center;
                    margin-top: 10px;
                    button {
                        width: 90px;
                        height: 30px;
                        background: #ff8600;
                        font-size: 14px;
                        font-weight: Bold;
                        text-align: center;
                        color: #ffffff;
                        border: none;
                        border-radius: 4px;
                    }
                }
            }
        }

        .related-recommend {
            margin-top: 24px;
            margin-bottom: 24px;
            padding: 0 0 0 28px;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            ul {
                display: flex;
                flex-wrap: wrap;
                margin: 18px 0 20px;
                li {
                    width: 386px;
                    box-sizing: border-box;
                    padding-left: 10px;
                    display: flex;
                    justify-content: space-between;
                    position: relative;
                    display: flex;
                    justify-content: space-between;

                    p {
                        color: #333;
                        font-size: 16px;
                        line-height: 37px;

                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        cursor: pointer;
                    }
                    span {
                        display: block;
                        line-height: 37px;
                        color: #666;
                        font-size: 16px;
                    }
                }
                li::before {
                    content: '';
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    position: absolute;
                    left: 0;
                    top: 18px;
                    line-height: 37px;
                    background: #ffac13;
                }
                li:nth-child(2n + 1) {
                    border-right: 1px solid #e6e6e6;
                    padding-right: 4px;
                    p {
                        width: 280px;
                    }
                    span {
                        width: 80px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
                li:nth-child(2n + 2) {
                    margin-left: 18px;
                    width: 382px;
                    p {
                        width: 276px;
                    }
                    span {
                        width: 80px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
    .right-article-detail {
        width: 316px;

        .article-source-wrap {
            padding: 0 18px;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            .com-title {
                div {
                    img {
                        height: 29px;
                    }
                }
            }
            .article-source {
                display: flex;
                justify-content: space-between;
                padding-top: 14px;
                img {
                    width: 106px;
                    height: 142px;
                    margin-bottom: 26px;
                    box-shadow: 0px 0px 6px 1px rgba(15, 11, 5, 0.2);
                }
                .article-source-main {
                    width: 162px;
                    h5,
                    p:nth-child(2) {
                        width: 162px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    h5 {
                        font-size: 16px;
                        margin-bottom: 10px;
                        cursor: pointer;
                    }
                    p {
                        font-size: 14px;
                        color: #666;
                        line-height: 22px;
                        span {
                            font-size: 14px;
                            color: #666;
                            line-height: 22px;
                        }
                    }
                    p:nth-child(3) {
                        width: 162px;
                        text-overflow: -o-ellipsis-lastline;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 4;
                        line-clamp: 4;
                        -webkit-box-orient: vertical;
                    }
                }
            }
        }
        .anthology-catalog-wrap {
            padding: 0 18px;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            margin-top: 24px;
            .com-title {
                div {
                    img {
                        height: 29px;
                    }
                }
            }
            .anthology-catalog {
                ul {
                    padding: 10px 0 14px;
                    li {
                        line-height: 37px;
                        height: 37px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        padding-left: 10px;
                        position: relative;
                        font-size: 16px;
                        color: #333;
                        width: 278px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        cursor: pointer;
                        box-sizing: border-box;
                    }
                    li >>> .role4 {
                        vertical-align: middle;
                        width: 16px !important;
                        height: 16px !important;
                        margin-top: -2px;
                    }
                    li::before {
                        content: '';
                        width: 4px;
                        height: 4px;
                        border-radius: 50%;
                        background: #ffac13;
                        position: absolute;
                        left: 0;
                        top: 18px;
                    }
                }
            }
        }
    }
}

// .author-btn {
//     display: none;
// }
</style>
